/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./campaign-channels.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./campaign-channels.component";
var styles_CampaignChannelsComponent = [i0.styles];
var RenderType_CampaignChannelsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CampaignChannelsComponent, data: {} });
export { RenderType_CampaignChannelsComponent as RenderType_CampaignChannelsComponent };
function View_CampaignChannelsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "channel-cell"]], [[4, "color", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, _co.getChannelBackground(_v.context.$implicit)); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getChannelColor(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.getChannelName(_v.context.$implicit); _ck(_v, 3, 0, currVal_2); }); }
function View_CampaignChannelsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignChannelsComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listChannels; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CampaignChannelsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignChannelsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.channels; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CampaignChannelsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-campaign-channels", [], null, null, null, View_CampaignChannelsComponent_0, RenderType_CampaignChannelsComponent)), i1.ɵdid(1, 114688, null, 0, i3.CampaignChannelsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CampaignChannelsComponentNgFactory = i1.ɵccf("app-campaign-channels", i3.CampaignChannelsComponent, View_CampaignChannelsComponent_Host_0, { channels: "channels" }, {}, []);
export { CampaignChannelsComponentNgFactory as CampaignChannelsComponentNgFactory };
