import { OnInit, OnChanges, Input, EventEmitter, Output, Component } from '@angular/core';

@Component({
    selector: 'app-campaign-pages',
    templateUrl: './campaign-pagination.component.html',
    styleUrls: ['./campaign-pagination.component.css']
})
export class CampaignPaginationComponent implements OnInit, OnChanges {

    @Input()
    activePage: number;
    @Input()
    disabled: boolean;
    @Output()
    pageChanged = new EventEmitter<number>();

    @Input()
    numberOfPages: number;

    hasNextItem: boolean;
    hasPreviousItem: boolean;
    numSequence: number[] = [0, 1, 2];

    ngOnChanges(): void {
        if (this.numberOfPages >= 3) {
           if (this.activePage === 0) {
              this.fillFirstPage();
            } else if (this.numSequence.indexOf(this.activePage) >= 0) {
                 this.fillForExistingItem();
            } else {
                this.fillNewPage();
            }
        } else {
          const numSequence = Array(3);
          for (let i = 0; i < 3; i++) {
              if (i < this.numberOfPages) {
                numSequence[i] = i;
              } else {
                numSequence.pop();
              }
          }
          this.hasNextItem = false;
          this.hasPreviousItem = false;
          this.numSequence = numSequence;
        }
    }

    fillFirstPage() {
      const numSequence = Array(3);
      this.hasPreviousItem = false;
      numSequence[0] = 0;
      numSequence[1] = 1;
      numSequence[2] = 2;
      this.hasNextItem = this.numberOfPages > 3;
      this.numSequence = numSequence;
    }

    fillNewPage() {
      const i = this.activePage;
      const numSequence = Array(3);
      this.hasPreviousItem = true;
      numSequence[0] = i - 1;
      numSequence[1] = i;
      if (i + 1 < this.numberOfPages) {
         numSequence[2] = i + 1;
      } else {
        numSequence.pop();
    }
    this.hasNextItem = i + 2 < this.numberOfPages;
    this.numSequence = numSequence;
    this.fillArray();
    }

    fillForExistingItem() {
      this.hasPreviousItem = this.numSequence[0] > 0;
      this.fillArray();
      this.hasNextItem = this.numSequence[this.numSequence.length - 1] + 1 < this.numberOfPages;
    }

    fillArray() {
      if (this.hasPreviousItem && this.numSequence.length < 3) {
        const numSequence = new Array(3);
        const missingNumbers = 3 - this.numSequence.length;
        let first = this.numSequence[0];
        for (let i = missingNumbers - 1 ; i >= 0; i --) {
            first = first - 1;
            numSequence[i] = first;
        }
        for (let i = 0; i <= missingNumbers ; i++) {
           numSequence[missingNumbers + i] = this.numSequence[i];
        }
        this.numSequence = numSequence;
      }
    }

    ngOnInit(): void {
    }

    pageClicked(page) {
      this.pageChanged.emit(page);
    }

    getPreviousPage() {
      const sequence = this.numSequence;
      const lastPage = sequence[sequence.length - 1];
      const firstPage = sequence[0];
      if (this.activePage === firstPage && this.activePage > 0) {
        return firstPage - 1;
      } else {
        return firstPage;
      }
    }

    getNextPage() {
      const sequence = this.numSequence;
      const lastPage = sequence[sequence.length - 1];
      const firstPage = sequence[0];
      if (this.activePage === firstPage) {
        return sequence[1];
      }
      if (this.activePage === lastPage) {
        return lastPage + 1;
      }
      return lastPage;
    }
}
