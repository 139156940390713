<div>
  <hr class="divider"/>
  <ul class="pagination">
    <li *ngIf = "hasPreviousItem"  (click)="pageClicked(getPreviousPage())" class="page-item">
      <button class="page-button" [disabled]="disabled">
        <mat-icon class="page-icon">navigate_before</mat-icon>
      </button>
    </li>
    <li (click)="pageClicked(page)" class="page-item" *ngFor="let page of numSequence; let i = index">
          <button [disabled]="disabled" *ngIf="page !== activePage" class="page-button"> {{page + 1}} </button>
          <button [disabled]="disabled" *ngIf="page === activePage" class="active-button"> {{page + 1}} </button>
    <li>
    <li *ngIf = "hasNextItem"  (click)="pageClicked(getNextPage())" class="page-item">
       <button class="page-button" [disabled]="disabled">
        <mat-icon class="page-icon">navigate_next</mat-icon>
       </button>
    </li>
  </ul>