import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpService } from '../services/http.service';
import { UserProfileService } from '../services/user-profile.service';
import { deserializeArray } from 'class-transformer';
import { CampaignInfo, CampaignState } from 'flow-model';
import { DateTimeFormatter, Instant, ZoneId, ZonedDateTime } from 'js-joda';
import { SnackBarService } from '../services/snackbar.service';
enum SortDirection {
  DESC,
  ASC
}
@Component({
    selector: 'app-campaign-overview',
    templateUrl: './campaign-overview.component.html',
    styleUrls: ['./campaign-overview.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class CampaignOverviewComponent implements OnInit {

    lastCampaignId: string = null;

    scanIndexForward = false;

    campaigns: CampaignInfo[] = [];

    pageUpdateInProgress = false;

    activePage = 0;
    numberOfPages = 0;
    pageSize = UserProfileService.CAMPAIGNS_LIMIT;

    selectedTab = 'All Campaigns';

    selectedTabIndex = 0;

    allTabs: string[] = ['All Campaigns', 'Uploaded', 'Active', 'Scheduled', 'Finished', 'Error'];

    stateTabs: string[] = ['All Campaigns', 'Uploaded', 'Active', 'Scheduled', 'Finished', 'Error'];

    campaignsCount: number[] = [5, 0, 0, 0, 0, 0];

    activeState = 'Uploaded';
    errorState = 'Error';

    displayedColumns: string[] = ['name', 'state', 'flowName', 'channels', 'createdTime', 'executionTime'];

    sortedColumn: string = null;
    sortDirection = null;
    private customerChannels;

    constructor(private http: HttpService, private userProfileService: UserProfileService,
      private snackBar: SnackBarService) {
      userProfileService.channels$.subscribe(channels => {
        if (channels) {
          for (const channel of channels) {
            if (this.customerChannels) {
              this.customerChannels += ',' + channel.toString();
            } else {
              this.customerChannels = channel.toString();
            }
          }
        }
      });
    }

    ngOnInit(): void {
      this.loadCampaignsCount();
      this.loadInitialData();
    }

    loadCampaignsCount() {
      this.pageUpdateInProgress = true;
      this.http.loadAccountCampaignsStats().subscribe(accountCampaignsStats => {
        this.stateTabs = ['All Campaigns'];
        this.campaignsCount = [accountCampaignsStats.allCount];
        if (accountCampaignsStats.newCount) {
          this.campaignsCount.push(accountCampaignsStats.newCount);
          this.stateTabs.push(this.allTabs[1]);
        }
        if (accountCampaignsStats.runningCount) {
          this.campaignsCount.push(accountCampaignsStats.runningCount);
          this.stateTabs.push(this.allTabs[2]);
        }
        if (accountCampaignsStats.scheduledCount) {
           this.campaignsCount.push(accountCampaignsStats.scheduledCount);
           this.stateTabs.push(this.allTabs[3]);
        }
        if (accountCampaignsStats.finishedCount) {
        this.campaignsCount.push(accountCampaignsStats.finishedCount);
        this.stateTabs.push(this.allTabs[4]);
        }
        if (accountCampaignsStats.errorCount) {
        this.campaignsCount.push(accountCampaignsStats.errorCount);
        this.stateTabs.push(this.allTabs[5]);
        }
      });
    }

    loadInitialData() {
        this.pageUpdateInProgress = true;
        this.http.loadSortedCampaigns(null, this.scanIndexForward, (UserProfileService.CAMPAIGNS_LIMIT * (3 + 0)) + 1, null,
       null, null, 0).subscribe((response: string) => {
        const campaigns = deserializeArray(CampaignInfo, response);
        this.calculatePageComponent(campaigns, campaigns.length > UserProfileService.CAMPAIGNS_LIMIT * (3 + 0), 0);
        this.pageUpdateInProgress = false;
      }, e => {
        this.campaigns = [];
        this.pageUpdateInProgress = false;
        this.snackBar.showError('Load campaigns failed: ' + e.error);
      });
    }

    getStateColor(state) {
        switch (state) {
            case CampaignState.RUNNING:
              return '#50CE87';
            case CampaignState.ERROR:
              return 'red';
            case CampaignState.STOPPED:
              return '#E9B501';
            case CampaignState.NEW:
              return '#0076A1';
            case CampaignState.SCHEDULED:
              return '#1B85AC';
            default:
              return 'white';
        }
    }

    loadAllCampaigns(event) {
      if (this.sortedColumn === null) {
        this.updateCampaigns(null,  event);
      } else {
       this.getSortedCampaigns(event, this.sortedColumn);
      }
    }

    getFilteredCampaigns(pageNumber: number, campaignState: CampaignState) {
      this.getCampaignsByState(pageNumber, campaignState);
    }

    getFilteredCampaignState(): CampaignState {
       if (this.selectedTab === this.allTabs[0]) {
          return null;
       } else if (this.selectedTab === this.allTabs[1]) {
          return CampaignState.NEW;
       } else if (this.selectedTab === this.allTabs[2]) {
        return CampaignState.RUNNING;
       } else if (this.selectedTab === this.allTabs[3]) {
        return CampaignState.SCHEDULED;
       } else if (this.selectedTab === this.allTabs[4]) {
        return CampaignState.STOPPED;
       } else if (this.selectedTab === this.allTabs[5]) {
        return CampaignState.ERROR;
       }
    }

    getFilterBySizeHint(): number {
      const index = this.stateTabs.findIndex(x => x === this.selectedTab);
      if (index <= 0) {
        return null;
      }
      return this.campaignsCount[index];
    }


    pageChanged(pageNumber: number) {
        this.pageUpdateInProgress = true;
        if (this.selectedTab === this.stateTabs[0]) {
          this.scanIndexForward = false;
          this.loadAllCampaigns(pageNumber);
          } else  {
             const state = this.getFilteredCampaignState();
             this.getFilteredCampaigns(pageNumber, state);
          }
    }

    refreshPage() {
      this.activePage = 0;
      this.scanIndexForward = false;
      this.sortedColumn = null;
      this.selectedTabIndex = 0;
      this.selectedTab = this.stateTabs[0];
      this.loadCampaignsCount();
      this.loadInitialData();
    }

    getDate(time: number) {
        if (time) {
        return ZonedDateTime.ofInstant(Instant.ofEpochSecond(time), ZoneId.SYSTEM).toLocalDateTime().format(DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss'));
        } else {
            return 'N/A';
        }
    }

    getStatusBackground(state) {
        switch (state) {
            case CampaignState.RUNNING:
              return '#E9FFF3';
            case CampaignState.ERROR:
              return 'pink';
            case CampaignState.STOPPED:
              return '#f5f5ca';
            case CampaignState.NEW:
              return 'rgb(183 207 216)';
            case CampaignState.SCHEDULED:
                return '#E5FAFF';
            default:
              return 'blue';
        }
    }

    stateTabSelected(tab) {
       const tabName = this.stateTabs[tab.index];
       this.selectedTabIndex = tab.index;
       if (tabName !== this.selectedTab) {
          this.selectedTab = this.stateTabs[tab.index];
          this.sortDirection = null;
          this.sortedColumn = null;
          this.activePage = 0;
          this.pageChanged(0);
       }
    }

    getDefaultSortDirectection(columnName) {
      if (columnName === 'executionTime' || columnName === 'createdTime') {
        return SortDirection.DESC;
      }
      return SortDirection.ASC;
    }

    sort(columnName) {
      if (this.pageUpdateInProgress) {
        return;
      }
      if (this.sortedColumn !== columnName) {
        this.sortDirection = this.getDefaultSortDirectection(columnName);
        this.sortedColumn = columnName;
      } else {
        if (this.sortDirection === SortDirection.ASC) {
          this.sortDirection = SortDirection.DESC;
        } else {
          this.sortDirection = SortDirection.ASC;
        }
      }
      this.getSortedCampaigns(0, columnName);
    }

    getSortedCampaigns(pageNumber: number, columnName: string) {
      let scanIndexForward = true;
      if (this.sortDirection === SortDirection.DESC) {
           scanIndexForward = false;
      }
      this.scanIndexForward = scanIndexForward;
      this.pageUpdateInProgress = true;
      const state = this.getFilteredCampaignState() !== null ? this.getFilteredCampaignState().toString() : null;
      let filterBySizeHint = null;
      if (state) {
        filterBySizeHint = this.getFilterBySizeHint();
      }
      this.http.loadSortedCampaigns(null, this.scanIndexForward,
        (UserProfileService.CAMPAIGNS_LIMIT * (3)) + 1, columnName, state, filterBySizeHint, UserProfileService.CAMPAIGNS_LIMIT * pageNumber).subscribe((response: string) => {
        const campaigns = deserializeArray(CampaignInfo, response);
        this.calculatePageComponent(campaigns, campaigns.length > UserProfileService.CAMPAIGNS_LIMIT * (3 + pageNumber), pageNumber);
        this.pageUpdateInProgress = false;
      }, e => {
        this.campaigns = [];
        this.pageUpdateInProgress = false;
        this.snackBar.showError('Load campaigns failed: ' + e.error);
      });
    }

    getCampaignsByState(pageNumber: number, campaignState: CampaignState) {
      let scanIndexForward = true;
      if (this.sortDirection === SortDirection.DESC) {
           scanIndexForward = false;
      }
      this.scanIndexForward = scanIndexForward;
      this.pageUpdateInProgress = true;
      const filterBySizeHint = this.getFilterBySizeHint();
      this.http.loadSortedCampaigns(null, this.scanIndexForward, (UserProfileService.CAMPAIGNS_LIMIT * (3)) + 1, this.sortedColumn,
       campaignState, filterBySizeHint, UserProfileService.CAMPAIGNS_LIMIT * pageNumber).subscribe((response: string) => {
        const campaigns = deserializeArray(CampaignInfo, response);
        this.calculatePageComponent(campaigns, campaigns.length > UserProfileService.CAMPAIGNS_LIMIT * (3 + pageNumber), pageNumber);
        this.pageUpdateInProgress = false;
      }, e => {
        this.pageUpdateInProgress = false;
        this.campaigns = [];
        this.snackBar.showError('Load campaigns failed: ' + e.error);
      });
    }

    loadFirstItems() {
       this.pageUpdateInProgress = true;
       this.scanIndexForward = null;
       this.updateCampaigns(null , 0);
    }

    updateCampaigns(lastCampaignId: string = null, pageNumber: number) {
        this.http.loadSortedCampaigns(lastCampaignId, this.scanIndexForward, (UserProfileService.CAMPAIGNS_LIMIT * (3)) + 1, null,
        null, null, UserProfileService.CAMPAIGNS_LIMIT * pageNumber).subscribe((response: string) => {
         const campaigns = deserializeArray(CampaignInfo, response);
         this.calculatePageComponent(campaigns, campaigns.length > UserProfileService.CAMPAIGNS_LIMIT * (3 + pageNumber), pageNumber);
         this.pageUpdateInProgress = false;
       }, e => {
        this.campaigns = [];
        this.pageUpdateInProgress = false;
        this.snackBar.showError('Load campaigns failed: ' + e.error);
      });
    }

    calculatePageComponent(pageItems: CampaignInfo[], hasNext: boolean, pageNumber: number) {
      this.activePage = pageNumber;
      this.numberOfPages = ((pageNumber * UserProfileService.CAMPAIGNS_LIMIT) + pageItems.length) / this.pageSize;
      if (pageItems.length > 0) {
        const campaigns: CampaignInfo[] = [];
         for (let i = 0; (i < this.pageSize ) && (i < pageItems.length); i++) {
          if (pageItems[i].executionTime && (pageItems[i].channels === null || pageItems[i].channels === undefined)) {
            pageItems[i].channels = this.customerChannels;
          }
          campaigns.push(pageItems[i]);
         }
         this.campaigns = campaigns;
      } else {
        this.campaigns = pageItems;
      }
    }
}
