import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '../services/report.service';
import { SnackBarService } from '../services/snackbar.service';
import { UserProfileService } from '../services/user-profile.service';
import { PeriodicAuditReport, AuditRow, ReportPeriodType, ReportFilter, ReportResult, DateAsNumber, Country } from 'flow-model';
import * as download from 'downloadjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report-page',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  static readonly MS_PER_DAY: number = 1000 * 60 * 60 * 24;

  static readonly ALL_COUNTRIES: string = '[ALL]';

  static readonly dlm: string = ReportComponent.getSeparator();

  @ViewChild('reportForm') reportForm: ElementRef;

  private months: string;

  private weeks: string;

  private monthsValues: string[];

  private weeksValues: string[];

  private country: string;

  private startDate: Date;

  private endDate: Date;

  private countries: Country[];

  showProgress: boolean;

  userRole: String;

  reports: PeriodicAuditReport[];

  reportResult: ReportResult;

  reportType: ReportPeriodType;

  reportTypeKeys: ReportPeriodType[];

  customerId: string;

  stats: AggregateStats;

  minDate: Date;

  maxDate: Date;

  minDatePeriod: Date;

  maxDatePeriod: Date;

  private static getSeparator(): string {
    return ',';
  }

  constructor(private route: ActivatedRoute,
    private reportService: ReportService,
    private userProfileService: UserProfileService,
    private snackBar: SnackBarService) {


    this.showProgress = false;
    this.reportType = ReportPeriodType.MONTHLY;
    this.reportTypeKeys = [ReportPeriodType.MONTHLY, ReportPeriodType.WEEKLY, ReportPeriodType.DAILY];

    this.months = '1';
    this.monthsValues = ['1', '2', '3', '4'];

    this.weeks = '1';
    this.weeksValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];

    reportService.reports$.subscribe(reportResult => {
      if (reportResult) {
        this.reportResult = reportResult;
        this.reports = reportResult.periodicAuditReports;
        this.stats = this.createAggregationData();
        this.showProgress = false;
      }
    });

    reportService.reportsError$.subscribe(reportErrorResult => {
      if (reportErrorResult) {
        this.snackBar.showError('Failed to load reports');
        this.showProgress = false;
      }
    });

    reportService.countries$.subscribe(countriesResult => {
      if (countriesResult) {
        this.countries = countriesResult;
      }
    });
    reportService.fetchCountries();

    this.country = ReportComponent.ALL_COUNTRIES;

    userProfileService.userRole$.subscribe(userRole => {this.userRole = userRole; });

    this.initMinMaxDate();
  }

  private initMinMaxDate(): void {
    const currentDate = Date.now();

    this.minDatePeriod = new Date(currentDate - 100 * ReportComponent.MS_PER_DAY);
    this.setStartOfTheDay(this.minDatePeriod);

    this.maxDatePeriod = new Date(currentDate);
    this.setStartOfTheDay(this.maxDatePeriod);

    this.minDate = this.minDatePeriod;
    this.maxDate = this.maxDatePeriod;
  }

  ngOnInit() {
  }

  getConversationStarted(row: AuditRow, channel: string): string {
    const counters = row.chanelCounters;
    if (counters != null && counters.length > 0) {
      for (const counter of counters) {
        if (counter.channel === channel) {
          return counter.conversationsCount.toString();
        }
      }
    }

    return '';
  }

  getSessionStarted(row: AuditRow, channel: string): string {
    const counters = row.chanelCounters;
    if (counters != null && counters.length > 0) {
      for (const counter of counters) {
        if (counter.channel === channel) {
          return counter.sessionsCount.toString();
        }
      }
    }

    return '';
  }

  getConversationStartedN(row: AuditRow, channel: string): number {
    const counters = row.chanelCounters;
    if (counters != null && counters.length > 0) {
      for (const counter of counters) {
        if (counter.channel === channel) {
          return counter.conversationsCount;
        }
      }
    }

    return 0;
  }

  getSessionStartedN(row: AuditRow, channel: string): number {
    const counters = row.chanelCounters;
    if (counters != null && counters.length > 0) {
      for (const counter of counters) {
        if (counter.channel === channel) {
          return counter.sessionsCount;
        }
      }
    }

    return 0;
  }

  reportsClick() {
    this.reports = new Array<PeriodicAuditReport>();

    const reportFilter = new ReportFilter();
    reportFilter.periodType = this.reportType;

    if (this.reportType === ReportPeriodType.MONTHLY) {
      reportFilter.months = parseInt(this.months, null);
    }

    if (this.reportType === ReportPeriodType.WEEKLY) {
      reportFilter.weeks = parseInt(this.weeks, null);
    }

    if (this.reportType === ReportPeriodType.DAILY) {
      if (!this.startDate || this.startDate === null || !this.endDate || this.endDate === null) {
        this.snackBar.showError('Please set values of start and end date for Daily reports');
        return;
      }
      if (this.startDate > this.endDate) {
        this.snackBar.showError('Please set start less or equal to end date for Daily reports');
        return;
      }
      if (Math.floor((this.endDate.getTime() - this.startDate.getTime()) / ReportComponent.MS_PER_DAY) > 10) {
        this.snackBar.showError('Please set maximum 10 days period between start and end date');
        return;
      }

      reportFilter.startDate = (this.startDate.getTime()) / 1000 - this.startDate.getTimezoneOffset() * 60;
      reportFilter.endDate = (this.endDate.getTime()) / 1000 - this.endDate.getTimezoneOffset() * 60;
    }

    reportFilter.customerId = this.customerId;
    reportFilter.countryCode = this.country;

    this.showProgress = true;

    this.reportService.fetchReports(reportFilter);
  }

  showMonthly(): boolean {
    return this.reportType === ReportPeriodType.MONTHLY;
  }

  showWeekly(): boolean {
    return this.reportType === ReportPeriodType.WEEKLY;
  }

  showDaily(): boolean {
    return this.reportType === ReportPeriodType.DAILY;
  }

  isMonthlyReport(): boolean {
    return this.matchReportType(ReportPeriodType.MONTHLY);
  }

  isWeeklyReport(): boolean {
    return this.matchReportType(ReportPeriodType.WEEKLY);
  }

  isDailyReport(): boolean {
    return this.matchReportType(ReportPeriodType.DAILY);
  }

  getCurrentDayDate(date: DateAsNumber): string {
    const dateObj = new Date(date * 1000);
    return new Date(date * 1000 + dateObj.getTimezoneOffset() * 60000).toString();
  }

  getAllCountries(): string {
    return ReportComponent.ALL_COUNTRIES;
  }

  csvClick(): void {
    this.generateCSVReport();
  }

  onStartDateChange(): void {
    if (!this.startDate && !this.endDate) {
      this.minDate = this.minDatePeriod;
      this.maxDate = this.maxDatePeriod;
      return;
    }

    if (this.startDate) {
      this.minDate = this.startDate;
      this.setStartOfTheDay(this.minDate);

      const newMaxDate = this.getDateAfterDays(10, this.startDate);
      this.maxDate = newMaxDate.getTime() < this.maxDatePeriod.getTime() ? newMaxDate : this.maxDatePeriod;
      return;
    }

    if (this.endDate) {
      const newMinDate = this.getDateBeforeDays(10, this.endDate);
      this.minDate = newMinDate.getTime() > this.minDatePeriod.getTime() ? newMinDate : this.minDatePeriod;
    }
  }

  onEndDateChange(): void {
    if (!this.startDate && !this.endDate) {
      this.minDate = this.minDatePeriod;
      this.maxDate = this.maxDatePeriod;
      return;
    }

    if (this.endDate) {
      this.maxDate = this.endDate;
      this.setStartOfTheDay(this.maxDate);

      const newMinDate = this.getDateBeforeDays(10, this.endDate);
      this.minDate = newMinDate.getTime() > this.minDatePeriod.getTime() ? newMinDate : this.minDatePeriod;
      return;
    }

    if (this.startDate) {
      const newMaxDate = this.getDateAfterDays(10, this.startDate);
      this.maxDate = newMaxDate.getTime() < this.maxDatePeriod.getTime() ? newMaxDate : this.maxDatePeriod;
    }
  }

  private setStartOfTheDay(date: Date): void {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  private getDateBeforeDays(days: number, date: Date): Date {
    const copy = new Date(date.getTime());
    this.setStartOfTheDay(copy);
    const result = new Date(copy.getTime() - ReportComponent.MS_PER_DAY * (days - 1));
    this.setStartOfTheDay(result);
    return result;
  }

  private getDateAfterDays(days: number, date: Date): Date {
    const copy = new Date(date.getTime());
    this.setStartOfTheDay(copy);
    const result = new Date(copy.getTime() + ReportComponent.MS_PER_DAY * days - 1);
    this.setStartOfTheDay(result);
    return result;
  }

  private matchReportType(type: ReportPeriodType): boolean {
    return this.reportResult && this.reportResult.reportFilter &&
      this.reportResult.reportFilter.periodType && this.reportResult.reportFilter.periodType === type;
  }

  private generateCSVReport(): void {
    if (this.reports && this.reports.length > 0) {
      const channels = this.getAllChannels();
      let body = this.createHeaders(channels);

      for (const report of this.reports) {
        if (report.rows && report.rows.length > 0) {
          let showPeriod = true;
          for (const row of report.rows) {
            body += this.createRow(report, row, channels, showPeriod);
            showPeriod = false;
          }
        } else {
          body += this.createNoDataRow(report, channels);
        }
      }

      body += this.createTotalHeaderRow(channels);
      body += this.createTotalDataRow(channels);

      const dataBlob = new Blob([body]);
      download(dataBlob, 'reports.csv', 'text/csv; charset=utf-8');
    }
  }

  private getAllChannels(): Set<string> {
    const channels = new Set<string>();
    for (const report of this.reports) {
      report.channels.forEach(value => channels.add(value));
    }
    return channels;
  }

  private createHeaders(channels: Set<string>): string {
    let channelsHeader = '';

    for (const channel of channels) {
      channelsHeader += ReportComponent.dlm + channel + ' Conversations';
      channelsHeader += ReportComponent.dlm + channel + ' Sessions';
    }

    return 'Period' + ReportComponent.dlm + 'Customer Name' + ReportComponent.dlm + 'Customer Id' + ReportComponent.dlm +
      'Country' + ReportComponent.dlm + 'All Conversations' + ReportComponent.dlm + 'All Sessions'
      + ReportComponent.dlm + 'SMS sent'
      + channelsHeader + '\n';
  }

  private createRow(report: PeriodicAuditReport, row: AuditRow, channels: Set<string>, showPeriod: boolean): string {
    let rowTxt = showPeriod ? this.getDateFormatted(report) + ReportComponent.dlm : ReportComponent.dlm;

    rowTxt += row.customerName;
    rowTxt += ReportComponent.dlm + row.customerId;
    rowTxt += ReportComponent.dlm + this.getCountry(row.countryCode);
    rowTxt += ReportComponent.dlm + row.conversationsCount;
    rowTxt += ReportComponent.dlm + row.sessionsCount;
    rowTxt += ReportComponent.dlm + row.smsCount;

    for (const channel of channels) {
      rowTxt += ReportComponent.dlm + this.getConversationStarted(row, channel);
      rowTxt += ReportComponent.dlm + this.getSessionStarted(row, channel);
    }
    rowTxt += '\n';
    return rowTxt;
  }

  private createNoDataRow(report: PeriodicAuditReport, channels: Set<string>): string {
    let rowTxt = this.getDateFormatted(report) + ReportComponent.dlm;

    const dlmCount = channels.size * 2 + 5;
    for (let i = 0; i < dlmCount; i++) {
      rowTxt += ReportComponent.dlm;
    }
    rowTxt += '\n';

    return rowTxt;
  }

  private createTotalHeaderRow(channels: Set<string>): string {
    let rowTxt = 'Total' + ReportComponent.dlm + ReportComponent.dlm + ReportComponent.dlm + ReportComponent.dlm + 'All Conversations'
                 + ReportComponent.dlm + 'All Sessions' + ReportComponent.dlm + 'SMS sent';

    for (const channel of channels) {
      rowTxt += ReportComponent.dlm + channel + ' Conversations';
      rowTxt += ReportComponent.dlm + channel + ' Sessions';
    }
    rowTxt += '\n';

    return rowTxt;
  }

  private createTotalDataRow(channels: Set<string>): string {
    let rowTxt = ReportComponent.dlm + ReportComponent.dlm + ReportComponent.dlm + ReportComponent.dlm + this.stats.conversationsCount
                 + ReportComponent.dlm + this.stats.sessionsCount + ReportComponent.dlm + this.stats.smsCount;

    for (const channel of channels) {
      rowTxt += ReportComponent.dlm + this.stats.getConversationCount(channel);
      rowTxt += ReportComponent.dlm + this.stats.getSessionCount(channel);
    }
    rowTxt += '\n';

    return rowTxt;
  }

  private getDateFormatted(report: PeriodicAuditReport): string {
    const datepipe: DatePipe = new DatePipe('en-US');

    if (this.isDailyReport()) {
      return datepipe.transform(this.getCurrentDayDate(report.startTime), 'dd MMMM yyyy');
    }

    if (this.isWeeklyReport()) {
      return datepipe.transform(this.getCurrentDayDate(report.startTime), 'dd.MM') + '-' + datepipe.transform(this.getCurrentDayDate(report.endTime), 'dd.MM yyyy');
    }

    if (this.isMonthlyReport()) {
      return datepipe.transform(this.getCurrentDayDate(report.startTime), 'MMMM yyyy');
    }
    return '';
  }

  private getCountry(countryCode: string): string {
    if (countryCode && countryCode.length > 0 && this.countries) {
      for (const country of this.countries) {
        if (country.code === countryCode) {
          return country.name;
        }
      }
    }
    return '';
  }

  private createAggregationData(): AggregateStats {
    const stats = new AggregateStats();

    if (this.reports && this.reports.length > 0) {
      const channels = this.getAllChannels();
      stats.channels = channels;
      for (const report of this.reports) {
        if (report.rows && report.rows.length > 0) {
          for (const row of report.rows) {
            stats.conversationsCount += row.conversationsCount;
            stats.sessionsCount += row.sessionsCount;
            stats.smsCount += row.smsCount;

            for (const channel of channels) {
              stats.increaseConversationCount(channel, this.getConversationStartedN(row, channel));
              stats.increaseSessionCount(channel, this.getSessionStartedN(row, channel));
            }
          }
        }
      }
    }
    return stats;
  }
}

class AggregateStats {

  conversations: Map<string, number>;
  sessions: Map<string, number>;
  conversationsCount: number;
  sessionsCount: number;
  smsCount: number;
  channels: Set<string>;

  constructor() {
    this.conversations = new Map<string, number>();
    this.sessions = new Map<string, number>();
    this.conversationsCount = 0;
    this.sessionsCount = 0;
    this.smsCount = 0;
    this.channels = new Set<string>();
  }

  increaseConversationCount(channel: string, count: number): void {
    this.increaseMapCount(this.conversations, channel, count);
  }

  increaseSessionCount(channel: string, count: number): void {
    this.increaseMapCount(this.sessions, channel, count);
  }

  private increaseMapCount(map: Map<string, number>, channel: string, count: number): void {
    let value = count;
    if (map.has(channel)) {
      value = map.get(channel) + count;
    }

    map.set(channel, value);
  }

  getConversationCount(channel: string): number {
    return this.getMapCount(this.conversations, channel);
  }

  getSessionCount(channel: string): number {
    return this.getMapCount(this.sessions, channel);
  }

  private getMapCount(map: Map<string, number>, channel: string): number {
    if (map.has(channel)) {
      return map.get(channel);
    }
    return 0;
  }
}
