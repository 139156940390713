<app-topbar></app-topbar>
<mat-drawer-container class="drawer-container">
    <mat-drawer-content class="overview-content">
<div style="width: 76%; margin-left: 12%;"> 
    <div class="campaign-label"> Campaigns
    <button class="refresh-button" (click)="refreshPage()" [disabled]="pageUpdateInProgress">
      <mat-icon class="refresh-icon">refresh</mat-icon>
    </button>
    </div>
    <div [@.disabled]="true">
    <mat-tab-group  [selectedIndex]=selectedTabIndex (selectedTabChange)="stateTabSelected($event)" animationDuration="0ms" dynamicHeight>
        <mat-tab [disabled]="pageUpdateInProgress" *ngFor="let tab of stateTabs; let i = index">
        <ng-template *ngIf="i === 0 || campaignsCount[i]" mat-tab-label>
                <span class="state-tab" matTooltip="{{tab}}" aria-label="">{{tab}} <div class="count-campaigns">{{campaignsCount[i]}}</div></span>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    </div>
    <div [@.disabled]="true">
    <table cdk-table [dataSource]="campaigns" class="campaign-table" fixedLayout="true">

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th class = "header" mat-header-cell *matHeaderCellDef> Campaign Name 
        <button mat-icon-button [disabled]="pageUpdateInProgress" (click)="sort('name')">
            <mat-icon *ngIf="sortedColumn !== 'name'" style="transform: rotate(90deg)" (click)="sort('name')">sync_alt</mat-icon>
            <mat-icon *ngIf="sortedColumn === 'name' && sortDirection === 0"  (click)="sort('name')">arrow_downward</mat-icon>
            <mat-icon *ngIf="sortedColumn === 'name' && sortDirection === 1"  (click)="sort('name')">arrow_upward</mat-icon>
          </button>
    </th>
    <td mat-cell class="campaign-cell" *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- State Column -->
  <ng-container matColumnDef="state">
    <th class = "header"  mat-header-cell *matHeaderCellDef> Status
      <button mat-icon-button [disabled]="pageUpdateInProgress" *ngIf="selectedTab === 'All Campaigns'" (click)="sort('state')">
        <mat-icon *ngIf="sortedColumn !== 'state'" style="transform: rotate(90deg)" (click)="sort('state')">sync_alt</mat-icon>
        <mat-icon *ngIf="sortedColumn === 'state' && sortDirection === 0"  (click)="sort('state')">arrow_downward</mat-icon>
        <mat-icon *ngIf="sortedColumn === 'state' && sortDirection === 1"  (click)="sort('state')">arrow_upward</mat-icon>
      </button>
    </th>
    <td mat-cell class="campaign-cell" *matCellDef="let element"> 
        <span  class="status-cell" [style.color]="getStateColor(element.state)" [ngStyle]="{'background-color':getStatusBackground(element.state)}">
        {{element.state |titlecase}}
        </span> 
    </td>
  </ng-container>

  <!-- Flow name -->
  <ng-container matColumnDef="flowName">
    <th class = "header" mat-header-cell *matHeaderCellDef> Flow Name
      <button mat-icon-button [disabled]="pageUpdateInProgress" (click)="sort('flowName')">
        <mat-icon *ngIf="sortedColumn !== 'flowName'" style="transform: rotate(90deg)" (click)="sort('flowName')">sync_alt</mat-icon>
        <mat-icon *ngIf="sortedColumn === 'flowName' && sortDirection === 0"  (click)="sort('flowName')">arrow_downward</mat-icon>
        <mat-icon *ngIf="sortedColumn === 'flowName' && sortDirection === 1"  (click)="sort('flowName')">arrow_upward</mat-icon>
      </button>
  </th>
    <td mat-cell class="campaign-cell" *matCellDef="let element"> {{element.flowName}} </td>
  </ng-container>

  <!-- channels -->
  <ng-container matColumnDef="channels">
    <th class = "header"  mat-header-cell *matHeaderCellDef> Channels </th>
    <td mat-cell class="campaign-cell" *matCellDef="let element"> 
        <app-campaign-channels [channels]="element.channels"> 
        </app-campaign-channels>
        </td>
  </ng-container>

  <ng-container matColumnDef="createdTime">
    <th class = "header" mat-header-cell *matHeaderCellDef> Date created 
        <button mat-icon-button [disabled]="pageUpdateInProgress">
            <mat-icon [disabled]="true" *ngIf="sortedColumn !== 'createdTime'" style="transform: rotate(90deg)" (click)="sort('createdTime')">sync_alt</mat-icon>
            <mat-icon [disabled]="true" *ngIf="sortedColumn === 'createdTime' && sortDirection === 0"  (click)="sort('createdTime')">arrow_downward</mat-icon>
            <mat-icon [disabled]="true" *ngIf="sortedColumn === 'createdTime' && sortDirection === 1"  (click)="sort('createdTime')">arrow_upward</mat-icon>
          </button>
    </th>
    <td mat-cell class="campaign-cell" *matCellDef="let element"> {{getDate(element.createdTime)}} </td>
  </ng-container>

  
  <ng-container matColumnDef="executionTime">
    <th  class = "header" mat-header-cell *matHeaderCellDef> Start date
        <button mat-icon-button *ngIf="selectedTab !== activeState" [disabled]="pageUpdateInProgress">
            <mat-icon *ngIf="sortedColumn !== 'executionTime'"  style="transform: rotate(90deg)" (click)="sort('executionTime')">sync_alt</mat-icon>
            <mat-icon *ngIf="sortedColumn === 'executionTime' && sortDirection === 0"  (click)="sort('executionTime')">arrow_downward</mat-icon>
            <mat-icon *ngIf="sortedColumn === 'executionTime' && sortDirection === 1"  (click)="sort('executionTime')">arrow_upward</mat-icon>
          </button>
    </th>
    <td mat-cell class="campaign-cell" *matCellDef="let element"> {{getDate(element.executionTime) }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 </table>
</div>
 <app-campaign-pages [disabled]="pageUpdateInProgress" [activePage]="activePage" (pageChanged)="pageChanged($event)" [numberOfPages]="numberOfPages">
 </app-campaign-pages>
</div>
</mat-drawer-content>
</mat-drawer-container>