<mat-toolbar class="tool-bar" color="primary" role="heading" >
  <img src="/assets/link_mobility_logo.png" style="height: 27px; width: 110px;padding-left: 32px;padding-right: 22px;" alt="LINK Mobility logo">
  <button mat-flat-button *ngIf="!flowName" [ngClass]="router.url === '/campaigns' ? 'selected-menu' : 'menu-button'" (click)="campaignViewer()">
     Campaigns
  </button>

  <button mat-flat-button [ngClass]="(router.url !== '/report' && router.url !== '/campaigns') ? 'selected-menu' : 'menu-button'" *ngIf="!flowName" class="menu-button" (click)="flowDesigner()">
    Flows
 </button>

 <button mat-flat-button [ngClass]="router.url === '/report' ? 'selected-menu' : 'menu-button'" (click)="report()" class="menu-button" *ngIf="!flowName && (userRole === 'SUPER_ADMIN' || userRole === 'GLOBAL_BUSINESS' || userRole === 'BUSINESS')">
    Reports
</button>

  <a (click)="backLink()" *ngIf="backTarget" data-testid="backButton" mat-icon-button>
    <mat-icon>arrow_back</mat-icon>
  </a>

  <ng-container *ngIf="flowName">
    Flows&nbsp;-&nbsp;
    <app-editable-label [ngClass]="dirty ? 'flow-name-dirty' : 'flow-name'"
                        [label]="flowName"
                        (valueChanged)="rename($event)"
                        data-testid="flowName"
    ></app-editable-label>
    <sub><span *ngIf="dirty" class="flow-name-dirty flow-name-sub" data-testid="editorIsDirty">(modified)</span></sub>
  </ng-container>

  <ng-container *ngIf="flowName">
    <button mat-raised-button data-testid="channelButton" (click)="channelClick()">Channel</button>
  </ng-container>

  <span *ngIf="titleSuffix">&nbsp;{{ titleSuffix }}</span>

  <span class="fill_space"></span>

  <ng-container *ngIf="account">
    <a [href]="env.faqUrl" class="help-icon" matTooltip="Help" [matTooltipShowDelay]="env.tooltipDelay" target="_blank">
      <i class="fa fa-question-circle"></i>
    </a>
    <div class="user-icon" matTooltip="{{ account }} ({{ user }})">
      <i class="fa fa-user"></i>
    </div>
    <button mat-raised-button class="selected-menu" data-testid="logoutButton" (click)="logout()">Logout</button>
  </ng-container>

</mat-toolbar>
