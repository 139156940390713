import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FlowApplicationComponent } from './flow-application/flow-application.component';
import { AuthGuard } from './services/auth.guard';
import { LoginComponent } from './login/login.component';
import { ReportComponent } from './report/report.component';
import { FlowOverviewComponent } from './flow-overview/flow-overview.component';
import { CampaignOverviewComponent } from './campaign-overview/campaign-overview.component';

const appRoutes: Routes = [
  {
    path: 'editor/:id',
    component: FlowApplicationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'flows',
    component: FlowOverviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'campaigns',
    component: CampaignOverviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/flows'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
