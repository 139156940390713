import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { delay, flatMap, map, concatMap, retryWhen } from 'rxjs/operators';
import * as download from 'downloadjs';
import { of, throwError } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { AbstractNode, Flow, FlowDefinition } from 'flow-model';
import { MobileInvoiceInfoSettings, ReportResult, AccountSettings } from 'flow-model';
import { ChannelFeatureset, MobileInvoice } from 'conversation-domain';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HttpService {
    constructor(http) {
        this.http = http;
        this.retryCount = 4;
        this.retryWaitMilliSeconds = 1000;
        this.TARGET_MAPS = [
            { target: Flow, properties: { flowDefinition: FlowDefinition } },
            { target: FlowDefinition, properties: { messages: AbstractNode } }
        ];
    }
    static createHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
    sendAuthRequest(userName, passwordHash) {
        return this.http
            .post(`${environment.backendUrl}/login`, {
            username: userName,
            passwordHash: passwordHash
        });
    }
    loadFeatureset() {
        const headers = HttpService.createHeaders();
        return this.http
            .get(`${environment.backendUrl}/featureset`, { headers })
            .pipe(map(response => plainToClass(ChannelFeatureset, response)));
    }
    sendTestHttpRequestMessage(httpRequestMessageNode) {
        const headers = HttpService.createHeaders();
        return this.http.post(`${environment.backendUrl}/node`, httpRequestMessageNode, { headers, responseType: 'text' });
    }
    sendTestMobileInvoiceNode(mobileInvoiceNode) {
        const headers = HttpService.createHeaders();
        return this.http.post(`${environment.backendUrl}/mobile-invoice-node`, mobileInvoiceNode, { headers, responseType: 'text' });
    }
    loadSelectedFeatureset(channels) {
        const headers = HttpService.createHeaders();
        let params = new HttpParams();
        params = params.append('channels', channels);
        return this.http
            .get(`${environment.backendUrl}/channelFeatureset`, { headers: headers, params: params })
            .pipe(map(response => plainToClass(ChannelFeatureset, response)));
    }
    loadChannels() {
        const headers = HttpService.createHeaders();
        return this.http
            .get(`${environment.backendUrl}/channels`, { headers })
            .pipe(map(response => {
            if (response.length) {
                return response;
            }
            return new Array();
        }));
    }
    loadUserRole() {
        const headers = HttpService.createHeaders();
        return this.http
            .get(`${environment.backendUrl}/userrole`, { headers, responseType: 'text' }).pipe(map(response => plainToClass(String, response)));
    }
    loadMobileInvoice() {
        const headers = HttpService.createHeaders();
        return this.http
            .get(`${environment.backendUrl}/mobileInvoice`, { headers })
            .pipe(map(response => plainToClass(MobileInvoice, response)));
    }
    loadMobileInvoiceInfoSettings() {
        const headers = HttpService.createHeaders();
        return this.http
            .get(`${environment.backendUrl}/mobileinvoiceinfosettings`, { headers })
            .pipe(map(response => plainToClass(MobileInvoiceInfoSettings, response)));
    }
    loadAccountSettings() {
        const headers = HttpService.createHeaders();
        return this.http.get(`${environment.backendUrl}/accountsettings`, { headers })
            .pipe(map(response => plainToClass(AccountSettings, response)));
    }
    saveFlow(flowModel) {
        const headers = HttpService.createHeaders();
        return this.http.post(`${environment.backendUrl}/flow`, flowModel, { headers, responseType: 'text' });
    }
    loadFlow(flowId) {
        const headers = HttpService.createHeaders();
        return this.http
            .get(`${environment.backendUrl}/flow/${flowId}`, { headers })
            .pipe(map(response => plainToClass(Flow, response, { targetMaps: this.TARGET_MAPS })));
    }
    loadFlowList() {
        const headers = HttpService.createHeaders();
        return this.http.get(`${environment.backendUrl}/flows`, { headers, responseType: 'text' });
    }
    deleteFlow(flowId) {
        const headers = HttpService.createHeaders();
        return this.http.delete(`${environment.backendUrl}/flow/${flowId}`, { headers, responseType: 'text' });
    }
    forceTriggerFlow(msisdn, campaignData, flowId, channels, message, hideFreeText) {
        const csvFile = this.createCsvFile(campaignData, msisdn);
        return this.uploadShortCampaign(csvFile, `${msisdn}.csv`, flowId)
            .pipe(delay(1000), flatMap((newCampaignId) => this.forceTriggerCampaign(newCampaignId, channels, message, hideFreeText)));
    }
    scheduleTriggerFlow(msisdn, campaignData, flowId, startTime, channels, finalMessage, hideFreeText) {
        const csvFile = this.createCsvFile(campaignData, msisdn);
        return this.uploadShortCampaign(csvFile, `${msisdn}.csv`, flowId)
            .pipe(delay(1000), flatMap((newCampaignId) => this.scheduleTriggerCampaign(newCampaignId, startTime, channels, finalMessage, hideFreeText)));
    }
    scheduleTriggerCampaign(id, startTime, channels, finalMessage, hideFreeText) {
        let url = `${environment.backendUrl}/campaigns/${id}/schedule?startTime=` + startTime;
        if (channels) {
            url = url + '&channels=' + channels;
        }
        if (hideFreeText) {
            url = url + '&hideFreeText=' + hideFreeText;
        }
        return this.http.post(url, finalMessage).pipe(retryWhen(errorObject => errorObject.pipe(concatMap((error, count) => {
            if (count <= this.retryCount && error.status === 409) {
                return of(error);
            }
            return throwError(error);
        }), delay(this.retryWaitMilliSeconds))));
    }
    forceTriggerCampaign(id, channels, finalMessage, hideFreeText) {
        let url = `${environment.backendUrl}/campaigns/${id}/start?enforceStart=true`;
        if (channels) {
            url = url + '&channels=' + channels;
        }
        if (hideFreeText) {
            url = url + '&hideFreeText=' + hideFreeText;
        }
        return this.http.post(url, finalMessage).pipe(retryWhen(errorObject => errorObject.pipe(concatMap((error, count) => {
            if (count <= this.retryCount && error.status === 409) {
                return of(error);
            }
            return throwError(error);
        }), delay(this.retryWaitMilliSeconds))));
    }
    triggerFlow(msisdn, campaignData, flowId, channels, hideFreeText) {
        const csvFile = this.createCsvFile(campaignData, msisdn);
        return this.uploadShortCampaign(csvFile, `${msisdn}.csv`, flowId)
            .pipe(delay(1000), flatMap((newCampaignId) => this.triggerCampaign(newCampaignId, channels, hideFreeText)));
    }
    createCsvFile(campaignData, msisdn) {
        let variableNames;
        let variableValues;
        if (campaignData.size > 0) {
            variableNames = ';' + Array.from(campaignData.keys()).join(';');
            variableValues = ';' + Array.from(campaignData.values()).join(';');
        }
        else {
            variableNames = '';
            variableValues = '';
        }
        const csvFile = new Blob([`msisdn${variableNames}\n${msisdn}${variableValues}`], { type: 'text/csv' });
        return csvFile;
    }
    uploadCampaign(file, name, flowId) {
        const formData = new FormData();
        formData.append('flowId', flowId);
        formData.append('file', file, name);
        return this.http.post(`${environment.backendUrl}/campaigns`, formData, { responseType: 'text' });
    }
    uploadShortCampaign(file, name, flowId) {
        const formData = new FormData();
        formData.append('flowId', flowId);
        formData.append('file', file, name);
        return this.http.post(`${environment.backendUrl}/shortcampaigns`, formData, { responseType: 'text' });
    }
    deleteCampaign(id) {
        return this.http.delete(`${environment.backendUrl}/campaigns/${id}`);
    }
    triggerCampaign(id, channels, hideFreeText) {
        const formData = new FormData();
        formData.append('channels', channels);
        if (hideFreeText) {
            formData.append('hideFreeText', 'true');
        }
        return this.http.post(`${environment.backendUrl}/campaigns/${id}/start`, formData, {}).pipe(retryWhen(errorObject => errorObject.pipe(concatMap((error, count) => {
            if (count <= this.retryCount && error.status === 409) {
                return of(error);
            }
            return throwError(error);
        }), delay(this.retryWaitMilliSeconds))));
    }
    loadSortedCampaigns(lastCampaignId, scanIndexForward, limit, sortBy, filterByState, filterBySizeHint, offset) {
        let url = `${environment.backendUrl}/sortedcampaignitems?`;
        let paramsSet = false;
        if (lastCampaignId) {
            url = url + 'lastCampaignId=' + lastCampaignId;
            paramsSet = true;
        }
        if (scanIndexForward !== null && scanIndexForward !== undefined) {
            if (paramsSet) {
                url = url + '&';
            }
            url = url + 'scanIndexForward=' + scanIndexForward;
            paramsSet = true;
        }
        if (sortBy) {
            if (paramsSet) {
                url = url + '&';
            }
            url = url + 'sortBy=' + sortBy;
            paramsSet = true;
        }
        if (limit) {
            if (paramsSet) {
                url = url + '&';
            }
            url = url + 'limit=' + limit;
        }
        if (filterByState) {
            if (paramsSet) {
                url = url + '&';
            }
            url = url + 'filterByState=' + filterByState;
        }
        if (filterBySizeHint) {
            if (paramsSet) {
                url = url + '&';
            }
            url = url + 'filterBySizeHint=' + filterBySizeHint;
        }
        if (paramsSet) {
            url = url + '&';
        }
        url = url + 'offset=' + offset;
        return this.http.get(url, { responseType: 'text' });
    }
    loadAccountCampaignsStats() {
        const url = `${environment.backendUrl}/campaignsstats`;
        return this.http.get(url);
    }
    loadCampaignPage(lastCampaignId, scanIndexForward, limit) {
        let url = `${environment.backendUrl}/campaignitems?`;
        let paramsSet = false;
        if (lastCampaignId) {
            url = url + 'lastCampaignId=' + lastCampaignId;
            paramsSet = true;
        }
        if (scanIndexForward !== null && scanIndexForward !== undefined) {
            if (paramsSet) {
                url = url + '&';
            }
            url = url + 'scanIndexForward=' + scanIndexForward;
            paramsSet = true;
        }
        if (limit) {
            if (paramsSet) {
                url = url + '&';
            }
            url = url + 'limit=' + limit;
        }
        return this.http.get(url, { responseType: 'text' });
    }
    loadCampaigns() {
        return this.http.get(`${environment.backendUrl}/campaigns`, { responseType: 'text' });
    }
    downloadCampaignResult(campaign) {
        const getResult = this.http.get(`${environment.backendUrl}/campaigns/${campaign.id}/data`, { responseType: 'blob', observe: 'response' });
        getResult.subscribe(response => {
            download(response.body, campaign.name + '-result.csv', 'text/csv');
        });
        return getResult;
    }
    getCampaignStatistics(campaignId) {
        return this.http.get(`${environment.backendUrl}/campaigns/${campaignId}/statistics`);
    }
    stopCampaign(id, finalMessage) {
        return this.http.post(`${environment.backendUrl}/campaigns/${id}/stop`, finalMessage);
    }
    editScheduledCampaign(id, hideFreeText, finalMessage, startTime) {
        let url = `${environment.backendUrl}/campaigns/schedules/${id}/edit?startTime=` + startTime;
        if (hideFreeText) {
            url = url + '&hideFreeText=' + hideFreeText;
        }
        return this.http.post(url, finalMessage);
    }
    scheduleCampaign(id, startTime, channels, finalMessage, hideFreeText) {
        let url = `${environment.backendUrl}/campaigns/${id}/schedule?startTime=` + startTime;
        if (channels) {
            url = url + '&channels=' + channels;
        }
        if (hideFreeText) {
            url = url + '&hideFreeText=' + hideFreeText;
        }
        return this.http.post(url, finalMessage);
    }
    saveNewFlowName(oldName, newName, flowId) {
        return this.http.patch(`${environment.backendUrl}/flow/${flowId}`, {
            oldName, newName
        });
    }
    renameCampaign(campaignId, oldName, newName) {
        return this.http.patch(`${environment.backendUrl}/campaigns/${campaignId}`, {
            oldName, newName
        });
    }
    getMediaInfo(url) {
        return this.http.post(`${environment.backendUrl}/media/info`, url);
    }
    uploadMedia(file, name, accountId) {
        const formData = new FormData();
        formData.append('accountId', accountId);
        formData.append('file', file, name);
        return this.http.post(`${environment.backendUrl}/media/upload`, formData, { responseType: 'text' });
    }
    loadReports(reportFilter) {
        return this.http.post(`${environment.backendUrl}/reports`, reportFilter).pipe(map(response => plainToClass(ReportResult, response)));
    }
    loadCountries() {
        const headers = HttpService.createHeaders();
        return this.http
            .get(`${environment.backendUrl}/countries`, { headers }).pipe(map(response => {
            if (response.length) {
                return response;
            }
            return new Array();
        }));
    }
}
HttpService.ngInjectableDef = i0.defineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.inject(i1.HttpClient)); }, token: HttpService, providedIn: "root" });
