/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./campaign-pagination.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./campaign-pagination.component";
var styles_CampaignPaginationComponent = [i0.styles];
var RenderType_CampaignPaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CampaignPaginationComponent, data: {} });
export { RenderType_CampaignPaginationComponent as RenderType_CampaignPaginationComponent };
function View_CampaignPaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "page-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pageClicked(_co.getPreviousPage()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "page-button"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "page-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["navigate_before"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_CampaignPaginationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "page-button"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit + 1); _ck(_v, 1, 0, currVal_1); }); }
function View_CampaignPaginationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "active-button"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit + 1); _ck(_v, 1, 0, currVal_1); }); }
function View_CampaignPaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "page-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pageClicked(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignPaginationComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignPaginationComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit !== _co.activePage); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit === _co.activePage); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CampaignPaginationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "page-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pageClicked(_co.getNextPage()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "page-button"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "page-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["navigate_next"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_CampaignPaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "ul", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignPaginationComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignPaginationComponent_2)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignPaginationComponent_5)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasPreviousItem; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.numSequence; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.hasNextItem; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_CampaignPaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-campaign-pages", [], null, null, null, View_CampaignPaginationComponent_0, RenderType_CampaignPaginationComponent)), i1.ɵdid(1, 638976, null, 0, i5.CampaignPaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CampaignPaginationComponentNgFactory = i1.ɵccf("app-campaign-pages", i5.CampaignPaginationComponent, View_CampaignPaginationComponent_Host_0, { activePage: "activePage", disabled: "disabled", numberOfPages: "numberOfPages" }, { pageChanged: "pageChanged" }, []);
export { CampaignPaginationComponentNgFactory as CampaignPaginationComponentNgFactory };
