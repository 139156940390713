import { Component, Input, OnInit } from '@angular/core';
import { CampaignInfo } from 'flow-model';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-campaign-channels',
    templateUrl: './campaign-channels.component.html',
    styleUrls: ['./campaign-channels.component.css']
  })
  export class CampaignChannelsComponent implements OnInit {

    env = environment;

    @Input()
    channels: string;
    listChannels: string[] = [];

    ngOnInit(): void {
      if (this.channels) {
        this.listChannels = this.channels.split(',');
      }
    }

    getChannelColor(channel) {
      return 'white';
    }

    getChannelName(name: string) {
      switch (name) {
        case 'FBM':
          return 'Messenger';
        case 'RBM':
          return 'RCS';
        case 'WAM':
          return 'WhatsApp';
        case 'WEB':
          return 'Web Channel';
        default:
          return name;
    }
  }

    getChannelBackground(channel) {
      switch (channel) {
        case 'FBM':
          return '#00A3FE';
        case 'RBM':
          return 'orange';
        case 'WAM':
          return '#50CD89';
        case 'WEB':
          return '#0075A1';
        default:
          return 'gray';
    }
    }
}

