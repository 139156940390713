// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  backendUrl: 'https://flowhandler.dev.conversationalmessaging.com/linkflow',
  faqUrl: 'https://cdn-live.s3-eu-west-1.amazonaws.com/link-flow/development/FlowManual.html',

  tooltipDelay: 500,
  maximumSmsLength: 453,

  // Set to false to prevent a confirmation blocking unload / reload
  enableUnloadEvent: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
